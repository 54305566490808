<template>
  <div>
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :cancel_loading="cancel_loading"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnSaveNew="false"
      :btnDuplicate="false"
      :btnDelete="false"
    />
    <!--begin::New-->
    <PricingTemplate
      :productPricingTemplateId="productPricingTemplateId"
      :isReady="1"
      :displayName="1"
      :boxes="0"
      :redirect="true"
      :season="false"
      :expiry="false"
      :withCard="true"
      :withHelp="true"
      ref="pricingTemplate"
    ></PricingTemplate>
    <!--end::New-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import Buttons from "@/view/content/components/Buttons";

export default {
  // COMPONENTS
  components: {
    PricingTemplate,
    Buttons,
  },
  // DATA
  data() {
    return {
      save_loading: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("PRICING.PRICING_TEMPLATE"),
        route: "/configure/pricing",
      },
      { title: this.$t("PRICING.BOTON_NEW") },
    ]);
  },
  methods: {
    cancel() {
      this.$router.push("/configure/pricing");
    },
    async save() {
      await this.$refs.pricingTemplate.validateAndGetData().then(data => {
        this.save_loading = true;
        let productPricingTemplate = false;

        productPricingTemplate = data;

        //Here you can add all the following methods required to save more data.
        productPricingTemplate;
        if (productPricingTemplate) {
          if (productPricingTemplate.productPricingTemplateId === 0) {
            this.$refs.pricingTemplate.store(productPricingTemplate);
          } else {
            this.$refs.pricingTemplate.save(productPricingTemplate);
          }
        } else {
          this.save_loading = false;
        }
      });
    },
  },
  computed: {
    productPricingTemplateId() {
      return this.$route.params.id;
    },
  },
};
</script>
